<template>
    <div class="fee-settle">
        <el-dialog
            v-model="show"
            title="工单结算"
            width="500px"
        >
            <el-form :model="settleForm" label-position="left" label-width="100px">
                <el-row :gutter="50">
                    <el-col :span="24">
                        <el-form-item label="是否默认价格：" label-width="120px">
                            <el-radio-group v-model="settleForm.isDefault">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="-1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="工单价格：" label-width="120px">
                            <el-input
                                v-model="settleForm.price"
                                :disabled="settleForm.isDefault===1"
                                placeholder="请输入工单价格"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="show = false">取消</el-button>
                <el-button type="primary" @click="settle">结算</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {reactive, toRefs, watch} from "vue";
import {ORDER_SETTLE} from "../../api/dataProvider";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
    name: "FeeSettle",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["update:show", "updateList"],
    setup(props, context) {
        let state = reactive({
            settleForm: {
                isDefault: 1,
                price: null
            },
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
            if (val) {
                console.log(111)
            }
        })

        // 结算功能
        let settle = () => {
            ElMessageBox.confirm("提交结算后无法修改, 是否确认提交？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                ORDER_SETTLE(
                    {
                        id: props.id,
                        money: state.settleForm.price
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            ElMessage({
                                message: "结算成功",
                                type: "success",
                            });
                            updateList()
                        } else {
                            ElMessage({
                                message: res.data.message,
                                type: "error",
                            });
                            updateList()
                        }
                    }
                )
            })
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }

        return {
            ...toRefs(state),
            settle
        }
    }
}
</script>

<style lang="scss" scoped>

</style>