<template>
    <div class="search-toolbar">
        <div class="tool-item">
            <span class="item-label">任务号：</span>
            <el-input
                v-model="searchParams.number"
                class="item-input"
                clearable
                placeholder="请输任务号"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">车牌号码：</span>
            <el-input
                v-model="searchParams.plateNumber"
                class="item-input"
                clearable
                placeholder="请输车牌号码"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">车架号：</span>
            <el-input
                v-model="searchParams.vin"
                class="item-input"
                clearable
                placeholder="请输车架号"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">用户电话：</span>
            <el-input
                v-model="searchParams.phone"
                class="item-input"
                clearable
                placeholder="请输用户电话"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">开始时间：</span>
            <el-date-picker
                v-model="searchParams.startTime"
                class="item-datepicker"
                placeholder="请选择开始时间"
                type="datetime"
                @change="changeStartTime"
            >
            </el-date-picker>
        </div>
        <div class="tool-item">
            <span class="item-label">结束时间：</span>
            <el-date-picker
                v-model="searchParams.endTime"
                class="item-datepicker"
                placeholder="请选择结束时间"
                type="datetime"
                @change="changeEndTime"
            >
            </el-date-picker>
        </div>
        <div class="tool-item tool-buttons">
            <el-button type="primary" @click="getTaskList()">搜索</el-button>
        </div>
    </div>
    <div class="table-container">
        <div class="buttons-wrapper">
            <el-button type="primary" @click="exportExcel">导出excel</el-button>
        </div>
        <div class="table-wrapper">
            <el-table
                :cell-style="{textAlign:'center'}"
                :data="taskList"
                :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
            >
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    label="工单号"
                    prop="number"
                >
                </el-table-column>
                <el-table-column
                    label="平台"
                    prop="platform"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="救援类型"
                >
                    <template #default="scope">
                        {{ getLabel($store.state.task.serviceTypeList, scope.row.serviceType) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="工单状态"
                >
                    <template #default="scope">
                        {{ getLabel($store.state.task.statusList, scope.row.status) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="合同金额"
                    prop="moneyAmountTwo"
                >
                </el-table-column>
                <el-table-column
                    label="车牌号"
                    prop="plateNumber"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="车主姓名"
                    prop="name"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="联系电话"
                    prop="phone"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="报案时间"
                    prop="createdAt"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    label="救援地址"
                    prop="location"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="250"
                >
                    <template #default="scope">
                        <el-button size="mini" @click="openSettleDialog(scope.row.id)">结算</el-button>
                        <el-button size="mini" @click="openEditDialog(scope.row.id)">详情</el-button>
                        <el-button size="mini" @click="openLocusDialog(scope.row.id)">轨迹</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination
                :current-page="page.page"
                :page-size="page.size"
                :total="taskTotal"
                background
                layout="sizes,prev, pager, next,jumper"
                @current-change="changePageCurrent"
                @size-change="changePageSize"
            >
            </el-pagination>
        </div>
    </div>
    <!--结算弹窗-->
    <FeeSettle :id="settleDialog.id" v-model:show="settleDialog.show" @updateList="getTaskList()"></FeeSettle>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {GET_TICKETS_LIST} from "@/api/dataProvider";
import {useStore} from "vuex";
import FeeSettle from "./FeeSettle.vue"
import useFormatDateTime from "../../hooks/useFormatDateTime";

export default {
    name: "FeeListOnline",
    components:{
        FeeSettle
    },
    emits: ["edit", "locus"],
    props: {
        platform: String,
    },
    setup(props, context) {
        let $store = useStore()

        let state = reactive({
            searchParams: {
                number: null,
                plateNumber: null,
                vin: null,
                phone: null,
                startTime: null,
                endTime: null
            },
            taskList: [],
            taskSelection: [],
            taskTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
            // 结算弹窗
            settleDialog: {
                show: false,
                id: null
            }
        })

        onMounted(() => {
            getTaskList()
        })

        let {formatDateTime} = useFormatDateTime()

        // 更改开始时间
        let changeStartTime = (val) => {
            state.searchParams.startTime = formatDateTime(val)
        }

        // 更改结束时间
        let changeEndTime = (val) => {
            state.searchParams.endTime = formatDateTime(val)
        }

        // 获取工单列表
        let getTaskList = () => {
            GET_TICKETS_LIST(
                {
                    ...state.page,
                    ...state.searchParams,
                    status: "COMPLETED",
                    platform: props.platform.toUpperCase(),
                    isOnline: true
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.taskTotal = res.data.page.total
                        state.taskList = res.data.data
                    }
                })
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getTaskList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getTaskList()
        }

        // 打开编辑
        let openEditDialog = (id) => {
            context.emit("edit", id);
        }

        // 打开轨迹
        let openLocusDialog = (id) => {
            context.emit("locus", id);
        }

        // 打开结算
        let openSettleDialog = (id) => {
            state.settleDialog.id = id
            state.settleDialog.show = true
        }

        // 获取label方法
        let getLabel = (list, value) => {
            let label
            list.map((item) => {
                if (item.value === value) {
                    label = item.label
                }
            })
            return label
        }

        // 导出
        let exportExcel = () => {
            if (state.searchParams.startTime && state.searchParams.endTime) {
                location.href = "https://jiuyuan.demo.qoomoe.com/api/v1/ticket/exportSettlement?status=COMPLETED&startTime=" + state.searchParams.startTime + "&endTime=" + state.searchParams.endTime
            } else {
                location.href = "https://jiuyuan.demo.qoomoe.com/api/v1/ticket/exportSettlement?status=COMPLETED"
            }
        }

        return {
            ...toRefs(state),
            changeStartTime,
            changeEndTime,
            getTaskList,
            changePageCurrent,
            changePageSize,
            openEditDialog,
            openLocusDialog,
            openSettleDialog,
            getLabel,
            exportExcel
        }
    },
}
</script>