<template>
    <div class="fee-list">
        <el-tabs v-model="tabActive">
            <el-tab-pane label="线上工单">
                <FeeListOnline
                    :platform="$route.params.type"
                    @edit="openDetailDialog"
                    @locus="openLocusDialog"
                >
                </FeeListOnline>
            </el-tab-pane>
            <el-tab-pane label="线下工单">
                <FeeListOffline
                    :platform="$route.params.type"
                    @edit="openDetailDialog"
                    @locus="openLocusDialog"
                >
                </FeeListOffline>
            </el-tab-pane>
        </el-tabs>
    </div>
    <!--查看/编辑弹窗-->
    <FeeDetail :id="detailDialog.id" v-model:show="detailDialog.show"></FeeDetail>
    <!--轨迹弹窗-->
    <FeeLocus :id="locusDialog.id" v-model:show="locusDialog.show"></FeeLocus>
</template>

<script>
import {reactive, toRefs, watch} from "vue";
import FeeListOnline from "./FeeListOnline.vue";
import FeeListOffline from "./FeeListOffline.vue";
import FeeDetail from "./FeeDetail.vue";
import FeeLocus from "./FeeLocus.vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "FeeList",
    components: {
        FeeListOnline,
        FeeListOffline,
        FeeDetail,
        FeeLocus
    },
    setup() {
        let $store = useStore()
        let $router = useRouter()
        let $route = useRoute()

        let state = reactive({
            tabActive: "0",
            // 详情弹窗
            detailDialog: {
                show: false,
                id: null
            },
            // 轨迹弹窗
            locusDialog: {
                show: false,
                id: null
            }
        })

        // 打开详情弹窗
        let openDetailDialog = (id) => {
            state.detailDialog.id = id
            state.detailDialog.show = true
        }

        // 打开轨迹弹窗
        let openLocusDialog = (id) => {
            state.locusDialog.id = id
            state.locusDialog.show = true
        }

        return {
            ...toRefs(state),
            openDetailDialog,
            openLocusDialog
        }
    },
}
</script>
